import React from "react";
import {withTrans} from "../../i18n/withTrans";
import {List, ListItem} from "@material-ui/core";

const ListItems = ({t, data, type}) => {
    return (
        <List>
            {data.map(item =>
                <ListItem>{t(`rgpd.${type}.${item}`)}</ListItem>
            )}
        </List>
    )
};

export default withTrans(ListItems)
